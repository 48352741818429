import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useIsSubmitting } from "remix-validated-form";

export function SubmitButton(props: { formid?: string } & ButtonProps) {
  const isSubmitting = useIsSubmitting(props.formid);
  const { children, ...rest } = props;
  return (
    <Button
      type="submit"
      variant={"primary"}
      w={"full"}
      size={"lg"}
      {...rest}
      isLoading={isSubmitting}
    >
      {children}
    </Button>
  );
}

export function CancelButton(props: { formid?: string } & ButtonProps) {
  const isSubmitting = useIsSubmitting(props.formid);
  const { children, ...rest } = props;
  return (
    <Button
      type="reset"
      variant={"secondary"}
      w={"full"}
      size={"lg"}
      {...rest}
      isDisabled={isSubmitting}
    >
      {children}
    </Button>
  );
}
